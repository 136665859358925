import isObject from 'lodash/isObject';

import { trackEvent } from './analytics';
import { getAuthenticityToken, objectToGetParams } from './utils';

class ServerError extends Error {
  constructor(error) {
    if (isObject(error)) {
      super('multiple errors');
      Object.assign(this, error);
    } else {
      super(error);
    }
  }
}

class RateLimitError extends Error {
  constructor() {
    super('too many requests');
  }
}

class ChallengeError extends Error {
  constructor() {
    super('challenge required');
  }
}

const fetchApi = (path, opts = {}) => {
  let finalPath = path;

  opts.headers = Object.assign({}, {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': getAuthenticityToken(),
    'X-Requested-With': 'XmlHttpRequest',
  }, opts.headers || {});

  if (opts.body) {
    delete opts.headers['Content-Type']; // eslint-disable-line no-param-reassign
  } else {
    const { data } = opts;
    delete opts.data; // eslint-disable-line no-param-reassign

    if (data && (opts.method === 'GET' || !opts.method)) {
      finalPath = `${path}${objectToGetParams(data)}`;
    } else if (data) {
      opts.body = JSON.stringify(data);
    }
  }

  return fetch(finalPath, opts)
    .then((res) => {
      if (res.headers.get('cf-mitigated') === 'challenge') {
        trackEvent({
          category: 'API',
          action: 'Challenge',
          payload: {
            path,
          },
        });

        window.location.replace('/account/challenge');
        throw new ChallengeError();
      }

      if (res.status === 429) {
        throw new RateLimitError();
      }

      if (res.status === 204 || res.headers.get('content-length') === '0') {
        return null;
      }

      return res.json();
    })
    .then((data) => {
      if (data && (data.error || data.errors)) {
        throw new ServerError(data.error || data.errors);
      }

      return data;
    });
};

export default fetchApi;
